/**
 * Reservations API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */


import ApiClient from "../ApiClient";
import AuditHistoryItemIEnumerableResponse from '../model/AuditHistoryItemIEnumerableResponse';
import BooleanResponse from '../model/BooleanResponse';
import ClosureCommentListResponse from '../model/ClosureCommentListResponse';
import ClosureCommentResponse from '../model/ClosureCommentResponse';
import ClosureRequest from '../model/ClosureRequest';
import ClosureSummaryIEnumerableResponse from '../model/ClosureSummaryIEnumerableResponse';
import ClosureSummaryResponse from '../model/ClosureSummaryResponse';
import ClosureTypeIEnumerableResponse from '../model/ClosureTypeIEnumerableResponse';
import Response from '../model/Response';

/**
* AdminClosure service.
* @module api/AdminClosureApi
* @version v1
*/
export default class AdminClosureApi {

    /**
    * Constructs a new AdminClosureApi. 
    * @alias module:api/AdminClosureApi
    * @class
    * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
    * default to {@link module:ApiClient#instance} if unspecified.
    */
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }



    /**
     * @param {Number} closureId 
     * @param {String} tenantId 
     * @param {Object} opts Optional parameters
     * @param {String} opts.comment 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/ClosureCommentResponse} and HTTP response
     */
    v1TenantTenantIdAdminAdminClosureClosureIdAddCommentPostWithHttpInfo(closureId, tenantId, opts) {
      opts = opts || {};
      let postBody = null;
      // verify the required parameter 'closureId' is set
      if (closureId === undefined || closureId === null) {
        throw new Error("Missing the required parameter 'closureId' when calling v1TenantTenantIdAdminAdminClosureClosureIdAddCommentPost");
      }
      // verify the required parameter 'tenantId' is set
      if (tenantId === undefined || tenantId === null) {
        throw new Error("Missing the required parameter 'tenantId' when calling v1TenantTenantIdAdminAdminClosureClosureIdAddCommentPost");
      }

      let pathParams = {
        'closureId': closureId,
        'tenantId': tenantId
      };
      let queryParams = {
        'comment': opts['comment']
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['bearer'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = ClosureCommentResponse;
      return this.apiClient.callApi(
        '/v1/tenant/{tenantId}/admin/AdminClosure/{closureId}/add-comment', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * @param {Number} closureId 
     * @param {String} tenantId 
     * @param {Object} opts Optional parameters
     * @param {String} opts.comment 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/ClosureCommentResponse}
     */
    v1TenantTenantIdAdminAdminClosureClosureIdAddCommentPost(closureId, tenantId, opts) {
      return this.v1TenantTenantIdAdminAdminClosureClosureIdAddCommentPostWithHttpInfo(closureId, tenantId, opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * @param {Number} closureId 
     * @param {String} tenantId 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/ClosureCommentListResponse} and HTTP response
     */
    v1TenantTenantIdAdminAdminClosureClosureIdCommentsGetWithHttpInfo(closureId, tenantId) {
      let postBody = null;
      // verify the required parameter 'closureId' is set
      if (closureId === undefined || closureId === null) {
        throw new Error("Missing the required parameter 'closureId' when calling v1TenantTenantIdAdminAdminClosureClosureIdCommentsGet");
      }
      // verify the required parameter 'tenantId' is set
      if (tenantId === undefined || tenantId === null) {
        throw new Error("Missing the required parameter 'tenantId' when calling v1TenantTenantIdAdminAdminClosureClosureIdCommentsGet");
      }

      let pathParams = {
        'closureId': closureId,
        'tenantId': tenantId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['bearer'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = ClosureCommentListResponse;
      return this.apiClient.callApi(
        '/v1/tenant/{tenantId}/admin/AdminClosure/{closureId}/comments', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * @param {Number} closureId 
     * @param {String} tenantId 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/ClosureCommentListResponse}
     */
    v1TenantTenantIdAdminAdminClosureClosureIdCommentsGet(closureId, tenantId) {
      return this.v1TenantTenantIdAdminAdminClosureClosureIdCommentsGetWithHttpInfo(closureId, tenantId)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * @param {Number} closureId 
     * @param {String} tenantId 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/BooleanResponse} and HTTP response
     */
    v1TenantTenantIdAdminAdminClosureClosureIdDeleteWithHttpInfo(closureId, tenantId) {
      let postBody = null;
      // verify the required parameter 'closureId' is set
      if (closureId === undefined || closureId === null) {
        throw new Error("Missing the required parameter 'closureId' when calling v1TenantTenantIdAdminAdminClosureClosureIdDelete");
      }
      // verify the required parameter 'tenantId' is set
      if (tenantId === undefined || tenantId === null) {
        throw new Error("Missing the required parameter 'tenantId' when calling v1TenantTenantIdAdminAdminClosureClosureIdDelete");
      }

      let pathParams = {
        'closureId': closureId,
        'tenantId': tenantId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['bearer'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = BooleanResponse;
      return this.apiClient.callApi(
        '/v1/tenant/{tenantId}/admin/AdminClosure/{closureId}', 'DELETE',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * @param {Number} closureId 
     * @param {String} tenantId 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/BooleanResponse}
     */
    v1TenantTenantIdAdminAdminClosureClosureIdDelete(closureId, tenantId) {
      return this.v1TenantTenantIdAdminAdminClosureClosureIdDeleteWithHttpInfo(closureId, tenantId)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * @param {Number} closureId 
     * @param {String} tenantId 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/ClosureSummaryResponse} and HTTP response
     */
    v1TenantTenantIdAdminAdminClosureClosureIdGetWithHttpInfo(closureId, tenantId) {
      let postBody = null;
      // verify the required parameter 'closureId' is set
      if (closureId === undefined || closureId === null) {
        throw new Error("Missing the required parameter 'closureId' when calling v1TenantTenantIdAdminAdminClosureClosureIdGet");
      }
      // verify the required parameter 'tenantId' is set
      if (tenantId === undefined || tenantId === null) {
        throw new Error("Missing the required parameter 'tenantId' when calling v1TenantTenantIdAdminAdminClosureClosureIdGet");
      }

      let pathParams = {
        'closureId': closureId,
        'tenantId': tenantId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['bearer'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = ClosureSummaryResponse;
      return this.apiClient.callApi(
        '/v1/tenant/{tenantId}/admin/AdminClosure/{closureId}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * @param {Number} closureId 
     * @param {String} tenantId 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/ClosureSummaryResponse}
     */
    v1TenantTenantIdAdminAdminClosureClosureIdGet(closureId, tenantId) {
      return this.v1TenantTenantIdAdminAdminClosureClosureIdGetWithHttpInfo(closureId, tenantId)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * @param {Number} closureId 
     * @param {String} tenantId 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/AuditHistoryItemIEnumerableResponse} and HTTP response
     */
    v1TenantTenantIdAdminAdminClosureClosureIdHistoryGetWithHttpInfo(closureId, tenantId) {
      let postBody = null;
      // verify the required parameter 'closureId' is set
      if (closureId === undefined || closureId === null) {
        throw new Error("Missing the required parameter 'closureId' when calling v1TenantTenantIdAdminAdminClosureClosureIdHistoryGet");
      }
      // verify the required parameter 'tenantId' is set
      if (tenantId === undefined || tenantId === null) {
        throw new Error("Missing the required parameter 'tenantId' when calling v1TenantTenantIdAdminAdminClosureClosureIdHistoryGet");
      }

      let pathParams = {
        'closureId': closureId,
        'tenantId': tenantId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['bearer'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = AuditHistoryItemIEnumerableResponse;
      return this.apiClient.callApi(
        '/v1/tenant/{tenantId}/admin/AdminClosure/{closureId}/history', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * @param {Number} closureId 
     * @param {String} tenantId 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/AuditHistoryItemIEnumerableResponse}
     */
    v1TenantTenantIdAdminAdminClosureClosureIdHistoryGet(closureId, tenantId) {
      return this.v1TenantTenantIdAdminAdminClosureClosureIdHistoryGetWithHttpInfo(closureId, tenantId)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * @param {String} tenantId 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/ClosureTypeIEnumerableResponse} and HTTP response
     */
    v1TenantTenantIdAdminAdminClosureClosuretypesGetWithHttpInfo(tenantId) {
      let postBody = null;
      // verify the required parameter 'tenantId' is set
      if (tenantId === undefined || tenantId === null) {
        throw new Error("Missing the required parameter 'tenantId' when calling v1TenantTenantIdAdminAdminClosureClosuretypesGet");
      }

      let pathParams = {
        'tenantId': tenantId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['bearer'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = ClosureTypeIEnumerableResponse;
      return this.apiClient.callApi(
        '/v1/tenant/{tenantId}/admin/AdminClosure/closuretypes', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * @param {String} tenantId 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/ClosureTypeIEnumerableResponse}
     */
    v1TenantTenantIdAdminAdminClosureClosuretypesGet(tenantId) {
      return this.v1TenantTenantIdAdminAdminClosureClosuretypesGetWithHttpInfo(tenantId)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * @param {Number} locationId 
     * @param {String} tenantId 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/ClosureSummaryIEnumerableResponse} and HTTP response
     */
    v1TenantTenantIdAdminAdminClosureLocationLocationIdGetWithHttpInfo(locationId, tenantId) {
      let postBody = null;
      // verify the required parameter 'locationId' is set
      if (locationId === undefined || locationId === null) {
        throw new Error("Missing the required parameter 'locationId' when calling v1TenantTenantIdAdminAdminClosureLocationLocationIdGet");
      }
      // verify the required parameter 'tenantId' is set
      if (tenantId === undefined || tenantId === null) {
        throw new Error("Missing the required parameter 'tenantId' when calling v1TenantTenantIdAdminAdminClosureLocationLocationIdGet");
      }

      let pathParams = {
        'locationId': locationId,
        'tenantId': tenantId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['bearer'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = ClosureSummaryIEnumerableResponse;
      return this.apiClient.callApi(
        '/v1/tenant/{tenantId}/admin/AdminClosure/location/{locationId}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * @param {Number} locationId 
     * @param {String} tenantId 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/ClosureSummaryIEnumerableResponse}
     */
    v1TenantTenantIdAdminAdminClosureLocationLocationIdGet(locationId, tenantId) {
      return this.v1TenantTenantIdAdminAdminClosureLocationLocationIdGetWithHttpInfo(locationId, tenantId)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * @param {String} tenantId 
     * @param {Object} opts Optional parameters
     * @param {module:model/ClosureRequest} opts.closureRequest 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/BooleanResponse} and HTTP response
     */
    v1TenantTenantIdAdminAdminClosurePostWithHttpInfo(tenantId, opts) {
      opts = opts || {};
      let postBody = opts['closureRequest'];
      // verify the required parameter 'tenantId' is set
      if (tenantId === undefined || tenantId === null) {
        throw new Error("Missing the required parameter 'tenantId' when calling v1TenantTenantIdAdminAdminClosurePost");
      }

      let pathParams = {
        'tenantId': tenantId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['bearer'];
      let contentTypes = ['application/json-patch+json', 'application/json', 'text/json', 'application/_*+json'];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = BooleanResponse;
      return this.apiClient.callApi(
        '/v1/tenant/{tenantId}/admin/AdminClosure', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * @param {String} tenantId 
     * @param {Object} opts Optional parameters
     * @param {module:model/ClosureRequest} opts.closureRequest 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/BooleanResponse}
     */
    v1TenantTenantIdAdminAdminClosurePost(tenantId, opts) {
      return this.v1TenantTenantIdAdminAdminClosurePostWithHttpInfo(tenantId, opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * @param {String} tenantId 
     * @param {Object} opts Optional parameters
     * @param {module:model/ClosureRequest} opts.closureRequest 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/Response} and HTTP response
     */
    v1TenantTenantIdAdminAdminClosurePutWithHttpInfo(tenantId, opts) {
      opts = opts || {};
      let postBody = opts['closureRequest'];
      // verify the required parameter 'tenantId' is set
      if (tenantId === undefined || tenantId === null) {
        throw new Error("Missing the required parameter 'tenantId' when calling v1TenantTenantIdAdminAdminClosurePut");
      }

      let pathParams = {
        'tenantId': tenantId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['bearer'];
      let contentTypes = ['application/json-patch+json', 'application/json', 'text/json', 'application/_*+json'];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = Response;
      return this.apiClient.callApi(
        '/v1/tenant/{tenantId}/admin/AdminClosure', 'PUT',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * @param {String} tenantId 
     * @param {Object} opts Optional parameters
     * @param {module:model/ClosureRequest} opts.closureRequest 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/Response}
     */
    v1TenantTenantIdAdminAdminClosurePut(tenantId, opts) {
      return this.v1TenantTenantIdAdminAdminClosurePutWithHttpInfo(tenantId, opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


}
