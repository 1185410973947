/**
 * Reservations API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
 * The ClosureSpotSummary model module.
 * @module model/ClosureSpotSummary
 * @version v1
 */
class ClosureSpotSummary {
    /**
     * Constructs a new <code>ClosureSpotSummary</code>.
     * @alias module:model/ClosureSpotSummary
     */
    constructor() { 
        
        ClosureSpotSummary.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>ClosureSpotSummary</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/ClosureSpotSummary} obj Optional instance to populate.
     * @return {module:model/ClosureSpotSummary} The populated <code>ClosureSpotSummary</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new ClosureSpotSummary();

            if (data.hasOwnProperty('spotId')) {
                obj['spotId'] = ApiClient.convertToType(data['spotId'], 'Number');
            }
            if (data.hasOwnProperty('locationId')) {
                obj['locationId'] = ApiClient.convertToType(data['locationId'], 'Number');
            }
            if (data.hasOwnProperty('locationName')) {
                obj['locationName'] = ApiClient.convertToType(data['locationName'], 'String');
            }
            if (data.hasOwnProperty('spotName')) {
                obj['spotName'] = ApiClient.convertToType(data['spotName'], 'String');
            }
            if (data.hasOwnProperty('spotLoopName')) {
                obj['spotLoopName'] = ApiClient.convertToType(data['spotLoopName'], 'String');
            }
            if (data.hasOwnProperty('spotAreaName')) {
                obj['spotAreaName'] = ApiClient.convertToType(data['spotAreaName'], 'String');
            }
        }
        return obj;
    }


}

/**
 * @member {Number} spotId
 */
ClosureSpotSummary.prototype['spotId'] = undefined;

/**
 * @member {Number} locationId
 */
ClosureSpotSummary.prototype['locationId'] = undefined;

/**
 * @member {String} locationName
 */
ClosureSpotSummary.prototype['locationName'] = undefined;

/**
 * @member {String} spotName
 */
ClosureSpotSummary.prototype['spotName'] = undefined;

/**
 * @member {String} spotLoopName
 */
ClosureSpotSummary.prototype['spotLoopName'] = undefined;

/**
 * @member {String} spotAreaName
 */
ClosureSpotSummary.prototype['spotAreaName'] = undefined;






export default ClosureSpotSummary;

