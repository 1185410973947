/**
 * Reservations API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import ClosureSpotSummary from './ClosureSpotSummary';

/**
 * The ClosureSummary model module.
 * @module model/ClosureSummary
 * @version v1
 */
class ClosureSummary {
    /**
     * Constructs a new <code>ClosureSummary</code>.
     * @alias module:model/ClosureSummary
     */
    constructor() { 
        
        ClosureSummary.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>ClosureSummary</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/ClosureSummary} obj Optional instance to populate.
     * @return {module:model/ClosureSummary} The populated <code>ClosureSummary</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new ClosureSummary();

            if (data.hasOwnProperty('id')) {
                obj['id'] = ApiClient.convertToType(data['id'], 'Number');
            }
            if (data.hasOwnProperty('tenantId')) {
                obj['tenantId'] = ApiClient.convertToType(data['tenantId'], 'String');
            }
            if (data.hasOwnProperty('startDate')) {
                obj['startDate'] = ApiClient.convertToType(data['startDate'], 'Date');
            }
            if (data.hasOwnProperty('endDate')) {
                obj['endDate'] = ApiClient.convertToType(data['endDate'], 'Date');
            }
            if (data.hasOwnProperty('closureTypeId')) {
                obj['closureTypeId'] = ApiClient.convertToType(data['closureTypeId'], 'Number');
            }
            if (data.hasOwnProperty('closureTypeName')) {
                obj['closureTypeName'] = ApiClient.convertToType(data['closureTypeName'], 'String');
            }
            if (data.hasOwnProperty('isRecurring')) {
                obj['isRecurring'] = ApiClient.convertToType(data['isRecurring'], 'Boolean');
            }
            if (data.hasOwnProperty('overrideAllowed')) {
                obj['overrideAllowed'] = ApiClient.convertToType(data['overrideAllowed'], 'Boolean');
            }
            if (data.hasOwnProperty('includeMonday')) {
                obj['includeMonday'] = ApiClient.convertToType(data['includeMonday'], 'Boolean');
            }
            if (data.hasOwnProperty('includeTuesday')) {
                obj['includeTuesday'] = ApiClient.convertToType(data['includeTuesday'], 'Boolean');
            }
            if (data.hasOwnProperty('includeWednesday')) {
                obj['includeWednesday'] = ApiClient.convertToType(data['includeWednesday'], 'Boolean');
            }
            if (data.hasOwnProperty('includeThursday')) {
                obj['includeThursday'] = ApiClient.convertToType(data['includeThursday'], 'Boolean');
            }
            if (data.hasOwnProperty('includeFriday')) {
                obj['includeFriday'] = ApiClient.convertToType(data['includeFriday'], 'Boolean');
            }
            if (data.hasOwnProperty('includeSaturday')) {
                obj['includeSaturday'] = ApiClient.convertToType(data['includeSaturday'], 'Boolean');
            }
            if (data.hasOwnProperty('includeSunday')) {
                obj['includeSunday'] = ApiClient.convertToType(data['includeSunday'], 'Boolean');
            }
            if (data.hasOwnProperty('isDeleted')) {
                obj['isDeleted'] = ApiClient.convertToType(data['isDeleted'], 'Boolean');
            }
            if (data.hasOwnProperty('dateDeleted')) {
                obj['dateDeleted'] = ApiClient.convertToType(data['dateDeleted'], 'Date');
            }
            if (data.hasOwnProperty('closureSpots')) {
                obj['closureSpots'] = ApiClient.convertToType(data['closureSpots'], [ClosureSpotSummary]);
            }
        }
        return obj;
    }


}

/**
 * @member {Number} id
 */
ClosureSummary.prototype['id'] = undefined;

/**
 * @member {String} tenantId
 */
ClosureSummary.prototype['tenantId'] = undefined;

/**
 * @member {Date} startDate
 */
ClosureSummary.prototype['startDate'] = undefined;

/**
 * @member {Date} endDate
 */
ClosureSummary.prototype['endDate'] = undefined;

/**
 * @member {Number} closureTypeId
 */
ClosureSummary.prototype['closureTypeId'] = undefined;

/**
 * @member {String} closureTypeName
 */
ClosureSummary.prototype['closureTypeName'] = undefined;

/**
 * @member {Boolean} isRecurring
 */
ClosureSummary.prototype['isRecurring'] = undefined;

/**
 * @member {Boolean} overrideAllowed
 */
ClosureSummary.prototype['overrideAllowed'] = undefined;

/**
 * @member {Boolean} includeMonday
 */
ClosureSummary.prototype['includeMonday'] = undefined;

/**
 * @member {Boolean} includeTuesday
 */
ClosureSummary.prototype['includeTuesday'] = undefined;

/**
 * @member {Boolean} includeWednesday
 */
ClosureSummary.prototype['includeWednesday'] = undefined;

/**
 * @member {Boolean} includeThursday
 */
ClosureSummary.prototype['includeThursday'] = undefined;

/**
 * @member {Boolean} includeFriday
 */
ClosureSummary.prototype['includeFriday'] = undefined;

/**
 * @member {Boolean} includeSaturday
 */
ClosureSummary.prototype['includeSaturday'] = undefined;

/**
 * @member {Boolean} includeSunday
 */
ClosureSummary.prototype['includeSunday'] = undefined;

/**
 * @member {Boolean} isDeleted
 */
ClosureSummary.prototype['isDeleted'] = undefined;

/**
 * @member {Date} dateDeleted
 */
ClosureSummary.prototype['dateDeleted'] = undefined;

/**
 * @member {Array.<module:model/ClosureSpotSummary>} closureSpots
 */
ClosureSummary.prototype['closureSpots'] = undefined;






export default ClosureSummary;

