import AdminClosureApi from "@/generatedapiclients/src/api/AdminClosureApi.js";

export default class AdminClosureService {
  constructor(tenantId) {
    this._tenantId = tenantId;
    this._adminClosureApi = new AdminClosureApi();
  }

  async getClosures(locationId) {
    const response = await this._adminClosureApi.v1TenantTenantIdAdminAdminClosureLocationLocationIdGet(
      locationId,
      this._tenantId
    );
    return response;
  }

  async deleteClosure(closureId) {
    const response = await this._adminClosureApi.v1TenantTenantIdAdminAdminClosureClosureIdDelete(
      closureId,
      this._tenantId
    );
    return response;
  }

  async addClosure(closureRequest) {
    const response = await this._adminClosureApi.v1TenantTenantIdAdminAdminClosurePost(
      this._tenantId,
      {
        closureRequest
      }
    );
    return response;
  }

  async updateClosure(closureRequest) {
    const response = await this._adminClosureApi.v1TenantTenantIdAdminAdminClosurePut(
      this._tenantId,
      {
        closureRequest
      }
    );
    return response;
  }

  async getClosureTypes() {
    const response = await this._adminClosureApi.v1TenantTenantIdAdminAdminClosureClosuretypesGet(
      this._tenantId
    );
    return response;
  }

  async getClosure(closureId) {
    const response = await this._adminClosureApi.v1TenantTenantIdAdminAdminClosureClosureIdGet(
      closureId,
      this._tenantId
    );
    return response;
  }

  getClosureComments(closureId) {
    return this._adminClosureApi.v1TenantTenantIdAdminAdminClosureClosureIdCommentsGet(
      closureId,
      this._tenantId
    );
  }

  addClosureComment(closureId, comment) {
    return this._adminClosureApi.v1TenantTenantIdAdminAdminClosureClosureIdAddCommentPost(
      closureId,
      this._tenantId,
      {
        comment: comment
      }
    );
  }

  getClosureHistory(closureId) {
    return this._adminClosureApi.v1TenantTenantIdAdminAdminClosureClosureIdHistoryGet(
      closureId,
      this._tenantId
    );
  }
}
